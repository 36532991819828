import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Form,
  message,
  Input,
  Modal,
  Space,
  Popconfirm,
  FloatButton
} from 'antd';
import { DeleteOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import './index.css';
import FeatureModal from '../OptionsFeatureModal/FeatureModal';
import SearchField from '../SearchField/SearchField';
import ResizableAntdTable from 'resizable-antd-table';

const DeviceOfflainTable = () => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [nameBtn, setNameBtn] = useState(null);
  const [clickRowId, setClickRowId] = useState();
  const [isGenerateIdOpen, setGenerateIdOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const isAdmin = useSelector(state => state.userInfo.isAdmin);

  const [searcher, setSearcher] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(10);
  const [value, setValue] = useState();
  const [searchValue, setSearchValue] = useState('')


  useEffect(() => {
    fetchListDataForwarders();
  }, []);

  const columns =
    isMobile ? [
    {
      title: 'Android ID',
      dataIndex: 'device_id',
      render: (text, record) => {
        return (
          <Space direction='vertical'>
            {record.device_id}
            {record.call_sign}
          </Space>
        )
      },
    },
    {
      title: 'Действия',
      render: (text, record) => {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ol style={{ paddingLeft: 0 }}>
                <Space direction='vertical' size="middle">
                  <li style={{ listStyleType: 'none' }}>
                    <Button type="primary"
                      icon={<EyeOutlined />}
                      size='small'
                      style={{ width: '100%', position: 'relative', padding: '0', display: 'block' }}
                      onClick={() => {
                        windowOpenApi(`api/device_offline/download/${record.device_id}.txt`);
                      }}>
                      Скачать
                    </Button>
                  </li>
                  <li style={{ listStyleType: 'none' }}>
                    <FeatureModal name={record.call_sign}
                      tel={record.telegram}
                      sub_id_name={record['user.id'] ? record['user.name'] || record['user.email'] : 'Отсутствует'}
                      flag='DeviceOfflineTable' />
                  </li>
                  <li style={{ listStyleType: 'none' }}>
                    <Popconfirm
                      title="Удаление устройства"
                      description="Вы уверены что хотите удалить устройство?"
                      onConfirm={() => deleteCurrentFile(`${record.device_id}`)}
                      okText="Да, я уверен"
                      cancelText="Отмена">
                      <Button type="primary"
                        icon={<DeleteOutlined />}
                        size='small'
                        style={{ width: '100%', position: 'relative', padding: '0', display: 'block' }}
                        danger />
                    </Popconfirm>
                  </li>
                </Space>
              </ol>
            </div>
          </>
        )
      }
    }
  ]
    :
    [
      {
        title: 'Android id',
        dataIndex: 'device_id',
        key: 'device_id',
        width: 200,
        render: (text, record) => {
          return (
            <>
              {text}
                </>
              )}
      },
      {
        title: 'Позывной',
        dataIndex: 'call_sign',
        key: 'call_sign',
        editable: true,
        // width: 150,
        render: (text, record) => {
          return (
            <>
              {clickRowId === record.id &&
                'call_sign' === nameBtn ? (
                <>
                  <Form.Item className='form-item' name={'call_sign'}>
                    <Input
                      className="tableInput"
                      ref={(input) => input && input.focus()}
                      onBlur={() => {
                        setClickRowId();
                        setNameBtn();
                      }}
                      defaultValue={record.call_sign}
                      value={text}
                      style={{ textAlign: 'center', border: '2px solid #1890ff', height: '40px', width: '100%', justifyContent: 'center', fontSize: '12px' }}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: '25px',
                    }}
                    onClick={() => {
                      setClickRowId(record.id);
                      setNameBtn('call_sign');
                    }}
                  >{text}</div>
                </>
              )}
            </>
          );
        },
      },
      {
        title: 'Пользователь',
        dataIndex: 'actualUser',
        key: 'actualUser',
        width: 150,
        render: (text, record) => {
          return record['user.id'] ? record['user.name'] || record['user.email'] : 'Отсутствует';
        }
      },
      {
        title: 'Телеграм',
        dataIndex: 'telegram',
        editable: true,
        key: 'telegram',
        width: 150,
        render: (text, record) => {
          return (
            <>
              {clickRowId === record.id &&
                'telegram' === nameBtn ? (
                <Form.Item className='form-item' name={'telegram'}>
                  <Input
                    className="tableInput"
                    ref={(input) => input && input.focus()}
                    onBlur={() => {
                      setClickRowId();
                      setNameBtn();
                    }}
                    defaultValue={record.telegram}
                    value={text}
                    style={{ textAlign: 'center', border: '2px solid #1890ff', height: '40px', width: '100%', justifyContent: 'center', fontSize: '12px' }}
                  />
                </Form.Item>
              ) : (
                <>
                  <div
                    style={{
                      height: '25px',
                    }}
                    onClick={() => {
                      setClickRowId(record.id);
                      setNameBtn('telegram');
                    }}
                  ><a href={`https://t.me/${text}`} target={'_blank'}>{text}</a></div>
                </>
              )}
            </>
          );
        }
      },
      {
        title: 'Hash',
        dataIndex: 'action',
        key: 'action',
        className: 'offline-action',
        width: 110,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div style={{textAlign: 'center'}}>
              <Space>
                <Button type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    windowOpenApi(`api/device_offline/download/${record.device_id}.dronevision`)
                  }}>
                  Скачать хэш
                </Button>
                <Popconfirm
                  title="Удаление устройства"
                  description="Вы уверены что хотите удалить устройство?"
                  onConfirm={() => deleteCurrentFile(`${record.device_id}`)}
                  okText="Да, я уверен"
                  cancelText="Отмена">
                  <Button type="primary"
                    icon={<DeleteOutlined />}
                    danger 
                  >Удалить устройство</Button>
                </Popconfirm>
              </Space>
            </div>
          )
        },
      },
    ];

  const fetchListDataForwarders = async () => {
    try {
      setLoading(true);
      request(`api/device_offline/${isAdmin ? 0 : userInfo.userId}`)
        .then((resData) => {
          setTotalData(resData.count);
          setData(resData.rows.map((el, i) => ({ ...el, key: i + 1 })));
        })
        .finally(() => {
          setSearcher(false);
          setLoading(false);
        });
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error);
    }
  };

  const GenerateNewIdModal = () => {
    const [form] = Form.useForm();
    const showModal = () => {
      setGenerateIdOpen(true);
    };

    const handleCancel = () => {
      setGenerateIdOpen(false);
      setSearchValue('')
      form.resetFields();
    };


    const onFinish = async (values) => {
      try {
        const resData = await request(`/api/device_offline/offline_registration`, 'POST', {
          user_id: userInfo.userId,
          device_id: values.device_id,
          date: new Date(),
          password: values.password,
          call_sign: values.call_sign,
          department: values.department,
          army_post: values.army_post
        });

        if (!resData.error) {
          message.success('Устройство успешно добавлено!');
        } else {
          message.error('Не удалось добавить устройство!');
        }

        fetchListDataForwarders();
        setGenerateIdOpen(false);
        
      } catch (error) {
        console.log('ERROR - onGenerateNewIdModal - forwarder>>>', error);
        if (error.message === 'Validation error') {
          message.info('ОШИБКА создания! Такой агент уже существует.');
        }
      }
    };
    return (
      <>
        <Button type="primary" onClick={showModal} style={{ float: 'right', marginBlock: 10 }}>
          Добавить оффлайн устройство
        </Button>
        <Modal title="Добавление устройства"
          open={isGenerateIdOpen}
          onOk={() => {
            form.validateFields()
              .then((values) => {
                form.resetFields();
                setSearchValue('')
                onFinish(values);
              })
              .catch((info) => {
                console.log('Validate failed: ', info);
              })
          }}
          onCancel={handleCancel}
          cancelText="Отмена"
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              label="Android ID"
              name="device_id"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать ID устройства!',
                },
              ]}
              initialValue={searchValue}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Ведомство"
              name="department"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать ведомство!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Позывной"
              name="call_sign"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать позывной!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Военная часть"
              name="army_post"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать военную часть!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  min: 8,
                  message: 'Длина пароля не менее 8 символов',
                },
                {
                  required: true,
                  message: 'Необходимо указать пароль!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }

  const deleteCurrentFile = async (filename) => {
    try {
      await request(`api/device_offline/delete/${filename}`, `DELETE`);
    } catch (err) {
      console.log(`error deleteCurrentFile: ${err}`);
    } finally {
      fetchListDataForwarders();
    }
  }

  const windowOpenApi = async (route) => {
    try {
      window.open(route);
    } catch (err) {
      console.log("Error inside downloadCurrentFile ", err);
    }
  }
  
  const onEdit = async (values) => {
    try {;
  
      await request(`/api/device_offline/edit`, 'PATCH', {
        id: clickRowId,
        call_sign: values['call_sign'],
        army_post: values['army_post'],
        telegram: values['telegram'],
      });
  
      await fetchListDataForwarders();
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.log('ERROR - onEdit >>>', error);
      if (error.message === 'Validation error') {
        message.info('ОШИБКА обновления! Проверьте введенные данные.');
      }
    }
  };


  return (
    <>
      <div className="ForwarderTable__header">
        <SearchField
          setValue={setValue}
          setTotalData={setTotalData}
          setData={setData}
          setSearcher={setSearcher}
          fetchList={fetchListDataForwarders}
          setSearchValue={setSearchValue}
          setGenerateIdOpen={setGenerateIdOpen}
        />
        <GenerateNewIdModal />
      </div>
      <Form form={form} onFinish={onEdit}>
        <ResizableAntdTable
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
            current: page,
            pageSize: pageSize,
            total: totalData,
            onChange: async (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
              const res = searcher ?
                await request(`/api/device_offline/find_with/${value}?offset=${page * 10}`)
                :
                await request(`/api/device_offline/${isAdmin ? 0 : userInfo.userId}?offset=${page * 10}`);
              setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })));
            },
          }}
          scroll={isMobile ? null : {
            x: 2500
          }}
          bordered
        />
      </Form>
      {searcher && <FloatButton icon={<SyncOutlined />} tooltip={`Очистить фильтр`} onClick={fetchListDataForwarders} />}
    </>
  )
}

export default DeviceOfflainTable;