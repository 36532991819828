import { Button, Input } from 'antd';
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

const { TextArea } = Input;

export function MessageInput({ showModal, handleSendMessage }) {
  const [localTextMessage, setLocalTextMessage] = useState('');

  const handleSendOnlyText = () => {
    if (localTextMessage.trim()) {
      handleSendMessage(localTextMessage);
      setLocalTextMessage('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendOnlyText();
    }
  };

  return (
    <div style={{ display: 'flex', margin: '10px 20px 0', height: '50px' }}>
      <Button
        style={{ height: '50px', width: '50px', fontSize: '19px' }}
        icon={<PaperClipOutlined />}
        onClick={showModal}
      />
      <TextArea
        rows={1}
        style={{ height: '50px', resize: 'none', marginLeft: '10px', flex: 1, fontSize: '1.1vw' }}
        placeholder="Сообщение..."
        value={localTextMessage}
        onChange={(e) => setLocalTextMessage(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button
        type="primary"
        icon={<SendOutlined />}
        style={{ marginLeft: '10px', height: '50px', width: '50px', fontSize: '17px' }}
        onClick={handleSendOnlyText}
      />
    </div>
  );
}