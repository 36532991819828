import React from 'react';
import { Form, Modal, Select, Input, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import './index.css';
import { GEO_OPTION_VALUES, MAP_FILENAME_PATTERN, MAP_TYPES } from '../../utils/consts';
import { useGeoDataModal } from './GeoDataModal.hooks';

export function GeoDataModal({
  actual,
  isGeoModalOpen,
  setIsGeoModalOpen,
  fetchGeoData,
}) {
  const {
    title,
    form,
    props,
    onCreate,
    selectedType,
    handleChange,
    fileList,
    setFileList,
  } = useGeoDataModal({
    actual,
  });

  return (
    <>
      <Modal
        title={title}
        open={isGeoModalOpen}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              if (fileList.length === 0) {
                message.error('Необходимо выбрать файл!');
                return; 
              }
              
              const file = fileList[0];
              const fileExtension = `.${file.name.split('.').pop()}`;
              const expectedExtension = selectedType === 'hgt' ? '.hgt' : '.mbtiles';
      
              if (fileExtension !== expectedExtension) {
                message.error(
                  `Расширение файла не совпадает с ${expectedExtension}!`
                );
                return;
              }

              form.resetFields();
              if (actual === GEO_OPTION_VALUES.MAPS) {
                onCreate(values); 
              }
              setIsGeoModalOpen(false);
              setFileList([]);
              fetchGeoData(actual);
        
            })
            .catch((info) => {
              console.log("Ошибка валидации:", info);
            });
            
        }}
        destroyOnClose={true}
        onCancel={() =>{
          setIsGeoModalOpen(false)
          form.resetFields(); 
          setFileList([]);
        } } cancelText="Отмена"
      >
        <Form form={form} layout='vertical'>
          {actual === GEO_OPTION_VALUES.MAPS ? (
            <>
              <Form.Item
                name='filename'
                label='Имя файла'
                rules={[
                  {
                    required: true,
                    message: 'Это поле не может быть пустым!',
                  },
                  {
                    pattern: MAP_FILENAME_PATTERN,
                    message: 'Имя файла может содержать только латинские буквы, цифры, дефис, нижнее подчеркивание и точку!',
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                name='type'
                label='Тип файла'
                style={{
                  position: 'relative',
                  width: '250px',
                  marginBottom: '3%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'Это поле не может быть пустым!',
                  },
                ]}>
                <Select
                  placeholder='Выберите тип данных'
                  options={MAP_TYPES}
                  value={selectedType}
                  onChange={handleChange}
                  style={{
                    width: '250px',
                  }}
                />
              </Form.Item>
            </>
          ) : null}
          <Form.Item valuePropName='fileList'
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (fileList.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Необходимо выбрать файл для загрузки!'));
                },
              },
            ]}
          >
            <Upload.Dragger {...props}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Перетащите сюда файлы, которые хотите загрузить
              </p>
              <p className='ant-upload-hint'>
                Поддерживается одиночная или массовая загрузка файлов.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
