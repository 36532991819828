import { fetchRefreshTokens } from '../http/refresh';
import { clearChunks, uploadChunk, uploadFileInChunks } from './loaderSlice';

const getThunkByType = type => {
  const thunkMap = {
    'uploads/uploadFileInChunks': uploadFileInChunks,
    'uploads/clearChunks': clearChunks,
    'uploads/uploadChunk': uploadChunk,
  };

  return thunkMap[type] || null;
};

let isRefreshing = false;
let refreshPromise = null;

export const refreshTokensMiddleware = store => next => async action => {
  if (action.type.endsWith('/rejected')) {
    const { payload, meta } = action;
    const { status } = payload.response || {};

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = (async () => {
          try {
            await fetchRefreshTokens();

            const userData = localStorage.getItem('userData');
            let token = null;
            if (userData) {
              token = JSON.parse(userData).token;
            }

            return token;
          } finally {
            isRefreshing = false;
            refreshPromise = null;
          }
        })();
      }

      // Дождаться завершения refreshTokens
      await refreshPromise;

      const retryActionArgs = { ...meta.arg, token: localStorage.getItem('userData').token };

      const originalThunkType = action.type.replace('/rejected', '');
      const originalThunk = getThunkByType(originalThunkType);

      if (originalThunk) {
        return store.dispatch(originalThunk(retryActionArgs));
      } else {
        console.error(`Thunk not found for type: ${originalThunkType}`);
      }
    }
  }

  return next(action);
};
