import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUserData } from '../utils/lib';
import { message } from 'antd';
import { fetchRefreshTokens } from '../http/refresh';

const uploadControllers = new Map();
const fileReferences = new Map();

const initialState = {
  uploads: [],
  hasLargeFile: false,
};

export const uploadChunk = createAsyncThunk(
  'uploads/uploadChunk',
  async (
    { i, totalChunks, formData, uploadId, controller },
    { dispatch, rejectWithValue }
  ) => {
    const { token } = getUserData();

    try {
      await axios.post('/api/app/map/upload_chunk', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        signal: controller.signal,
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          const overallProgress = Math.floor(
            ((i + progress / 100) / totalChunks) * 100
          );

          dispatch(
            updateUploadProgress({ id: uploadId, progress: overallProgress })
          );
        },
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadFileInChunks = createAsyncThunk(
  'uploads/uploadFileInChunks',
  async ({ values, mapType, uploadId }, { dispatch, rejectWithValue }) => {
    const file = fileReferences.get(uploadId);
    const chunkSize = 5 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const user_id = JSON.parse(localStorage.userData).userId;

    const existingController = uploadControllers.get(uploadId);
    if (existingController) {
      uploadControllers.delete(uploadId);
    }
    const controller = new AbortController();
    uploadControllers.set(uploadId, controller);

    try {
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkIndex', String(i));
        formData.append('totalChunks', String(totalChunks));
        formData.append('fileName', file.name);
        formData.append('raw_path', values.filename);
        formData.append('user_id', user_id);
        formData.append('target', mapType);
        formData.append('type', values.type);

        try {
          await dispatch(uploadChunk({ i, totalChunks, formData, uploadId, controller })).unwrap();
        } catch (err) {
          if (err.response?.status === 401) {
            await fetchRefreshTokens();
            await dispatch(uploadChunk({ i, totalChunks, formData, uploadId, controller })).unwrap();
          } else {
            throw err;
          }
        }
      }

      if (!controller.signal.aborted) {
        dispatch(completeUpload({ id: uploadId }));
      } else {
        return;
      }
      uploadControllers.delete(uploadId);
      return { success: true };
    } catch (err) {
      console.error('Error uploading file:', err);

      dispatch(failUpload({ id: uploadId }));
      return rejectWithValue(err);
    } finally {
      uploadControllers.delete(uploadId);
    }
  }
);

const clearChunksRequest = async ({ fileName, target, token }) => {
  const response = await axios.post(
    '/api/app/map/clear_chunks',
    { fileName, target },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const clearChunks = createAsyncThunk(
  'uploads/clearChunks',
  async ({ fileName, target }, { rejectWithValue }) => {
    try {
      const { token } = getUserData();
      await clearChunksRequest({ fileName, target, token });

      return { success: true };
    } catch (err) {
      if (err.response?.status === 401) {
        try {
          await fetchRefreshTokens();

          const { token: newToken } = getUserData();
          await clearChunksRequest({ fileName, target, token: newToken });

          return { success: true };
        } catch (refreshError) {
          return rejectWithValue(refreshError.response || refreshError);
        }
      } else {
        return rejectWithValue(err.response || err);
      }
    }
  }
);

export const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    addUpload: (state, action) => {
      const fileSizeInGB = action.payload.file.size / 1024 ** 3;
      const isLargeFile = fileSizeInGB > 10;

      if (state.hasLargeFile) {
        message.error('Пожалуйста дождитесь загрузки большого файла ');
        return;
      }

      if (isLargeFile) {
        state.hasLargeFile = true;
      }

      fileReferences.set(action.payload.id, action.payload.file);
      state.uploads.push({
        id: action.payload.id,
        name: action.payload.name,
        file: {
          name: action.payload.file.name,
          size: action.payload.file.size,
        },
        values: action.payload.values,
        mapType: action.payload.mapType,
        token: action.payload.token,
        progress: 0,
        status: action.payload.status || 'active',
      });
    },
    updateUploadProgress: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      if (upload) {
        upload.progress = action.payload.progress;
      }
    },
    completeUpload: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      if (upload) {
        upload.status = 'completed';
      }
    },
    updateUploads: (state, action) => {
      state.uploads = action.payload;
    },
    activateUpload: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      if (upload) {
        upload.status = 'active';
      }
    },
    failUpload: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      if (upload) {
        upload.status = 'failed';
      }
    },
    awaitUpload: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      if (upload) {
        upload.status = 'await';
      }
    },
    removeUpload: (state, action) => {
      const upload = state.uploads.find(u => u.id === action.payload.id);
      const controller = uploadControllers.get(upload.id);
      if (upload) {
        upload.status = 'remove';
      }

      if (controller) {
        controller.abort();
        uploadControllers.delete(upload.id);
      }
    },
    removeFromArray: (state, action) => {
      const { id } = action.payload;
      uploadControllers.delete(id);
      state.uploads = state.uploads.filter(u => u.id !== id);
    },
  },
});

export const {
  addUpload,
  updateUploadProgress,
  completeUpload,
  failUpload,
  removeUpload,
  activateUpload,
  awaitUpload,
  updateUploads,
  removeFromArray,
} = uploadsSlice.actions;

export default uploadsSlice.reducer;
