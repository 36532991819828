import React, { useState } from 'react';
import {
  Button,
  Form,
  message,
  Modal,
  Calendar,
  theme,
  notification
} from 'antd';
import { useHttp } from '../../hooks/http.hook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'



const LicenseExtensionModal = (params) => {
  const { devices, setDevices } = params;
  const { request } = useHttp();
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  
  dayjs.extend(utc);

  const currentDate = dayjs.utc().minute(0).second(0).millisecond(0)
  const nextYearDate = dayjs(currentDate).add(180, 'days')
  const [calendarValue, setCalendarValue] = useState(nextYearDate)

  const { token } = theme.useToken();
  const wrapperStyle = {
    width: "100%",
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const openNotification = (custom) => {
    const key = `open${Date.now()}`;
    notification.open({
        message: custom,
        key,
    });
};

  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onFinish = async () => {
    try {
        const license_expires = calendarValue.minute(0).second(0).millisecond(0)
        const resDeviceData = await request(`/api/license/updateMany`, 'POST', {
        device_ids: devices.map(device => device.device_id),
        license_expires: license_expires
      });

      setIsOpen(false)
      setDevices([])
      if (!resDeviceData.error) {
        openNotification('Лицензии успешно обновлены! ✅');
      } else {
        openNotification('Не удалось обновить лицензии! ❌');
      }
    } catch (error) {
      console.log('ERROR  onGenerateNewIdModal  forwarder>>>', error);
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={devices.length < 1}
        style={{ marginLeft: 16 }}
      >
        Выдать
      </Button>
      <Modal title="Укажите дату истечения лицензии:"
        open={isOpen}
        onOk={() => {
          form.validateFields()
            .then((values) => {
              form.resetFields();
              onFinish(values);
            })
            .catch((info) => {
              console.log('Validate failed: ', info);
            })
        }}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            name="license_expires"
            initialValue={nextYearDate}
            style={{marginTop: "24px"}}
          >
            <div style={wrapperStyle}>
                <Calendar fullscreen={false} value={calendarValue} onChange={setCalendarValue} mode='month'/>   
            </div>


          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default LicenseExtensionModal;