import { useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import './index.css';
import { GEO_OPTION_VALUES } from '../../utils/consts';
import { useSelector, useDispatch } from 'react-redux';
import { addUpload, failUpload, uploadFileInChunks, awaitUpload, updateUploads, activateUpload } from '../../store/loaderSlice';
import { getTitleText } from '../../utils/lib';
import { setMapType } from '../../store/mapSlice';

export const useGeoDataModal = ({ actual }) => {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState('');
  const [fileList, setFileList] = useState([]);
  const mapType = useSelector(state => state.map.mapType);
  const uploads = useSelector(state => state.loader.uploads);
  const controllerRef = useRef(null);
  const processingRef = useRef(false);
  const [processedUploads, setProcessedUploads] = useState([]);
  

  const userData = localStorage.getItem('userData');
  let token = null;
  if (userData) {
    token = JSON.parse(userData).token;
  }

  const title = getTitleText(actual);
  const actionEndpoint =
    actual === GEO_OPTION_VALUES.MAPS
      ? '/api/app/map/upload_chunk'
      : `/api/lbs/kml_map/upload/${actual}`;

  const handleChange = value => {
    setSelectedType(value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedType === 'hgt') {
      dispatch(setMapType('.hgt'));
    } else {
      dispatch(setMapType('.mbtiles'));
    }
  }, [selectedType, mapType]);
  
  useEffect(() => {
    const firstUpload = uploads[0];
    const hasCompletedFirstUpload = firstUpload?.status === "completed";
    const isActiveUploadsEmpty = uploads.filter(upload => upload.status === "active").length === 0;
    const awaitUploads = uploads.filter(upload => upload.status === 'await');
    const isFirstUploadFailed = firstUpload?.status === "failed";
  
    if (hasCompletedFirstUpload && !processingRef.current) {
      processingRef.current = true; 
  
      const updatedUploads = [...uploads.slice(1), firstUpload];
  
      if (JSON.stringify(uploads) !== JSON.stringify(updatedUploads)) {
        dispatch(updateUploads(updatedUploads)); 
      }
  
      setTimeout(() => {
        processingRef.current = false;
      }, 0); 
    }
  
    if (isActiveUploadsEmpty && awaitUploads.length > 0 && (firstUpload?.status === 'completed' || firstUpload?.status === 'remove')) {
      const nextUpload = uploads.find(upload => upload.status === "await");
      
      dispatch(activateUpload({ id: nextUpload.id }));
      
      dispatch(
        uploadFileInChunks({
            file: nextUpload.file,
            values: nextUpload.values,
            mapType: nextUpload.mapType,
            uploadId: nextUpload.id,
            token: nextUpload.token,
        })
      );
  
      setFileList([nextUpload.file]);
    }
  }, [uploads, dispatch]);
  
  useEffect(() => {
    const firstUpload = uploads[0];
  
    if (firstUpload && !processedUploads.includes(firstUpload.id)) {
      if (firstUpload.status === 'completed' && firstUpload.status !== 'remove') {
        message.success('Файл успешно загружен');
        setProcessedUploads((prev) => [...prev, firstUpload.id])
      } 
    }
  }, [uploads, processedUploads]);

  const onCreate = async values => {
    const newUploadId = crypto.randomUUID();
    controllerRef.current = new AbortController();

    const file = fileList[0];
    if (fileList.length === 0) {
      message.error('Выберите файл перед загрузкой!');
      return;
    }

    const fileExtension = file.name.split('.').pop();
    const filename = `${values.filename}_${selectedType}.${fileExtension}`;

    if (uploads.some(upload => upload.status === 'active')) {
      dispatch(
        addUpload({
          id: newUploadId,
          name: filename,
          file,
          values,
          mapType,
          token,
          status: 'await', 
        })
      );
    } else {
      dispatch(
        addUpload({
          id: newUploadId,
          name: filename,
          file,
          values,
          mapType,
          token,
        })
      );

    }

    try {
       if (uploads.some(upload => upload.status === 'active')) {
        dispatch(
          awaitUpload({ id: newUploadId })
        );
       }  else {
        await dispatch(
          uploadFileInChunks({
            file,
            values,
            mapType,
            token,
            uploadId: newUploadId,
          })
        ).unwrap();
  
        setFileList([]);
       }
    } catch (err) {
      dispatch(failUpload({ id: newUploadId }));

      if (err.response) {
        const { data } = err.response;
    
        if (data) {
          if (data.message) {
            message.error(data.message || 'Ошибка загрузки файла');
          } else {
            message.error(data || 'Ошибка загрузки файла');
          }
        } else {
          message.error('Неизвестная ошибка');
        }
      }
    }
  };

  const handleRemove = file => {
    setFileList(prevFileList => prevFileList.filter(item => item.uid !== file.uid));
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    accept: actual === GEO_OPTION_VALUES.MAPS 
    ? (selectedType === 'hgt' ? '.hgt' : '.mbtiles') 
    : '.kml', 
    beforeUpload: file => {
      const isSizeValid = file.size / 1024 / 1024 / 1024 < 50;
      if (!isSizeValid) {
        message.error('Размер файла не должен превышать 50 ГБ!');
        return false;
      }

      setFileList([file]);

      if (actual === GEO_OPTION_VALUES.MAPS) return false;

      return true;
    },
    action: actionEndpoint,
    headers: {
      authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
    },
    form: form,
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} успешно загружен на сервер.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} ошибка загрузки.`);
      }
    },
    onRemove: handleRemove,
  };

  return {
    title,
    form,
    selectedType,
    handleChange,
    onCreate,
    props,
    fileList,
    setFileList,
  };
};
