import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mapType: '.hgt',
};

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setMapType: (state, action) => {        
      state.mapType = action.payload;
    },
  },
});

export const { setMapType } = mapSlice.actions;
export default mapSlice.reducer;
