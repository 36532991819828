import React, { useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { Button, Space, Table, Radio } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import ChartMapTable from '../ChartMapTable/ChartMapTable';
import { isMobile } from 'react-device-detect';
import { GeoDataModal } from './GeoDataModal';
import './index.css';
import { GEO_OPTION_VALUES, GEO_OPTIONS } from '../../utils/consts';

export const GeoData = () => {
  const { request } = useHttp();
  const [actual, setActual] = useState('strongPoint');
  const [data, setData] = useState([]);
  const [isGeoModalOpen, setIsGeoModalOpen] = useState(false);

  useEffect(() => {
    fetchGeoData(actual);
  }, [actual]);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'filename',
      key: 'filename',
      render: text => <b>{text}</b>,
    },
    {
      title: 'Последнее изменение',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => (
        <b>{new Date(record.updatedAt).toLocaleString()}</b>
      ),
    },
    {
      title: 'Действия',
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(record.id)}>
            Скачать
          </Button>
          <Button
            danger
            type='link'
            icon={<DeleteOutlined />}
            onClick={async () => {
              deleteHandler(record.filename);
            }}>
            Удалить
          </Button>
        </Space>
      ),
    },
  ];
  const deleteHandler = async filename => {
    await request(`/api/lbs/kml_map/delete`, 'POST', {
      filename: filename,
    });
    fetchGeoData(actual);
  };

  const handleDownload = async id => {
    try {
      await window.open(`api/lbs/kml_map/download/${id}`);
    } catch (error) {
      console.log(`error inside handleDownload: ${error}`);
    }
  };

  const fetchGeoData = async actual => {
    if (actual === GEO_OPTION_VALUES.MAPS) return;

    const list = await request(`/api/lbs/kml_map?type=${actual}`);
    setData(list.map((el, i) => ({ ...el, key: i + 1 })));
  };

  return (
    <>
      <div className='GeoData__header'>
        <Radio.Group
          options={GEO_OPTIONS}
          onChange={({ target: { value } }) => setActual(value)}
          value={actual}
          optionType='button'
          style={{ marginBlock: 10, paddingBlock: 5, float: 'right' }}
        />
        <Button
          style={{ marginBlock: 10 }}
          type='primary'
          onClick={() => setIsGeoModalOpen(true)}>
          Добавить
        </Button>
        <GeoDataModal
          actual={actual}
          isGeoModalOpen={isGeoModalOpen}
          fetchGeoData={fetchGeoData}
          setIsGeoModalOpen={setIsGeoModalOpen}
        />
      </div>
      {actual === GEO_OPTION_VALUES.MAPS ? (
        <ChartMapTable />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={isMobile ? null : {
            x: 2500
          }}
          bordered
        />
      )}
    </>
  );
};

export default GeoData;
