import React, { useEffect, useRef } from "react";
// import {on} from "../../socket";


export const TranslationUDP = () => {
  // const videoRef = useRef();
  //
  // const mediaSource = useRef(null);
  // const sourceBuffer = useRef(null);

  // useEffect(() => {
  //   const videoElement = videoRef.current;
  //
  //   mediaSource.current = new MediaSource();
  //
  //   mediaSource.current.addEventListener("sourceopen", () => {
  //     sourceBuffer.current = mediaSource.current.addSourceBuffer("video/mp4; codecs=avc1.42E01E");
  //
  //     sourceBuffer.current.addEventListener("updateend", () => {
  //       if (mediaSource.current.readyState === "open") {
  //         mediaSource.current.endOfStream();
  //       }
  //     });
  //
  //     videoElement.srcObject = mediaSource.current;
  //     videoElement.play().catch((error) => {
  //       console.error("Autoplay error:", error);
  //     });
  //   });
  //
  //   mediaSource.current.addEventListener("error", (e) => {
  //     console.error("MediaSource error:", e);
  //   });
  //
  //   on('video-data', (videoData) => {
  //     console.log(`event video-data`);
  //     if (mediaSource.current.readyState === "open") {
  //       if (!sourceBuffer.current) {
  //         sourceBuffer.current = mediaSource.current.addSourceBuffer("video/mp4; codecs=avc1.42E01E");
  //         sourceBuffer.current.addEventListener("updateend", () => {
  //           if (mediaSource.current.readyState === "open") {
  //             mediaSource.current.endOfStream();
  //           }
  //         });
  //       }
  //
  //       sourceBuffer.current.appendBuffer(new Uint8Array(videoData));
  //     }
  //   });
  //
  //   return () => {
  //     if (mediaSource.current.readyState === "open") {
  //       mediaSource.current.endOfStream();
  //     }
  //   };
  // }, []);

 return (
    <div>
      <video controls>
        <source src="http://localhost:5000/api/streams/video" type="video/mp4" />
      </video>
    </div>
  );

}

export default TranslationUDP;