import React, { useState, useEffect } from 'react';
import { Button, Table, Form, message, Input, Modal, Space, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook';
import { useSelector } from 'react-redux';

const DeviceOfflainTable = () => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [isGenerateIdOpen, setGenerateIdOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userState);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(10);

  useEffect(() => {
    fetchListData();
  }, []);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: 'Ответственный',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) => {
        return record?.user?.id ? record['user']['name'] || record['user']['email'] : 'Отсутствует';
      },
    },
    {
      title: 'Департамент',
      dataIndex: 'department',
      key: 'department',
      render: (text, record) => {
        return record.department;
      },
    },
    {
      title: 'Подразделение',
      dataIndex: 'army_post',
      key: 'army_post',
      render: (text, record) => {
        return record.army_post;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <p
              style={{
                margin: 0,
                color: record.status === 'approved' ? '#12b000' : record.status === 'requested' ? '#c2bb02' : '#c90202',
              }}
            >
              {record.status === 'approved'
                ? 'Подтвержден'
                : record.status === 'requested'
                ? 'На подтверждении'
                : 'Отменен'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '120px',
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Space>
              {record.status === 'approved' && (
                <>
                  <Tooltip title="Скачать ключ лицензии">
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={async () => {
                        downloadKey(record.name);
                      }}
                    ></Button>
                  </Tooltip>
                </>
              )}
              {record.status !== 'cancelled' && (
                <>
                  <Tooltip title={`${record.status === `requested` ? `Отменить` : `Удалить`} заявку`}>
                    <Popconfirm
                      title="Удаление заявки"
                      description="Вы уверены что хотите отменить заявку?"
                      onConfirm={() => cancelRequestHandler(`${record.name}`)}
                      okText="Да, я уверен"
                      cancelText="Отмена"
                    >
                      <Button type="primary" icon={record.status !== 'requested' && <DeleteOutlined />} danger>
                        {record.status === 'requested' && 'Отмена'}
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const fetchListData = async () => {
    try {
      setLoading(true);
      request(
        `/api/localmachine/getAllRequests?user_id=${userInfo.userId}&limit=${pageSize}&offset=${(page - 1) * pageSize}`
      )
        .then((resData) => {
          setTotalData(resData.count);
          setData(resData.rows?.map((el, i) => ({ ...el, key: i + 1 })));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error);
    }
  };

  const downloadKey = async (name) => {
    try {
      request(`/api/localmachine/getLicense?user_id=${userInfo.userId}&name=${name}`)
        .then((resData) => {
          const licenseKey = resData.key;
          const element = document.createElement('a');
          const file = new Blob([`${licenseKey}`], {
            type: 'text/plain',
          });
          element.href = URL.createObjectURL(file);
          element.download = `stormKey_${name}.txt`;
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
        .catch((e) => {
          message.error('Ошибка при формировании ключа');
          console.log(e);
        });
    } catch (error) {
      message.error('Ошибка при попытке загрузить лицензию');
      console.log('error-downloadKey >>>', error);
    }
  };

  const GenerateNewIdModal = () => {
    const [form] = Form.useForm();
    const showModal = () => {
      setGenerateIdOpen(true);
    };

    const handleCancel = () => {
      setGenerateIdOpen(false);
      form.resetFields();
    };

    const onFinish = async (values) => {
      try {
        const resData = await request(`/api/localmachine/createRequest`, 'POST', {
          user_id: userInfo.userId,
          name: values.name,
          department: values.department,
          army_post: values.army_post,
        });

        if (!resData.error) {
          message.success('Заявка успешно отправлена!');
        } else {
          message.error(resData.message);
        }

        fetchListData();
        setGenerateIdOpen(false);
      } catch (error) {
        console.log('ERROR - onGenerateNewIdModal - forwarder>>>', error);
        if (error.message === 'Validation error') {
          message.info('ОШИБКА создания!');
        }
      }
    };
    return (
      <>
        <Button type="primary" onClick={showModal} style={{ float: 'right', marginBlock: 10 }}>
          Зарегистрировать локальный сервер
        </Button>
        <Modal
          title="Регистрация локального сервераx"
          open={isGenerateIdOpen}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onFinish(values);
              })
              .catch((info) => {
                console.log('Validate failed: ', info);
              });
          }}
          onCancel={handleCancel}
          cancelText="Отмена"
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              label="Название"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать название сервера!',
                },
                {
                  min: 3,
                  message: 'Название должно быть более 3 символов!',
                },
                {
                  pattern: /^[^\s._]+$/,
                  message: 'Название не должно содержать пробелов, точки, нижнего подчеркивания!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Ведомство"
              name="department"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать ведомство!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Подразделение"
              name="army_post"
              rules={[
                {
                  required: true,
                  message: 'Необходимо указать подразделение!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  };

  const cancelRequestHandler = async (name) => {
    try {
      await request(`api/localmachine/changeRequestStatus`, `PATCH`, {
        name: name,
        status: 'cancelled',
      });
    } catch (err) {
      console.log(`error cancelRequestHandler: ${err}`);
    } finally {
      fetchListData();
    }
  };

  return (
    <>
      <div className="ForwarderTable__header">
        <GenerateNewIdModal />
      </div>
      <Form form={form}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
            current: page,
            pageSize: pageSize,
            total: totalData,
            onChange: async (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
              const response = await request(
                `/api/localmachine/getAllRequests?user_id=${userInfo.userId}&limit=${pageSize}&offset=${
                  (page - 1) * pageSize
                }`
              );
              setData(response.rows?.map((el, i) => ({ ...el, key: i + 1 })));
            },
          }}
          scroll={{
            x: 1200,
          }}
          bordered
        />
      </Form>
    </>
  );
};

export default DeviceOfflainTable;
