import React, { useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { Input, Popconfirm, Button } from 'antd';

const SearchRequests = (params) => {
    const [inputValue, setInputValue] = useState('');
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const {
      setValue,
      setTotalData,
      setData,
      setSearcher,
      fetchList,
      actualSegment,
      setSearchValue,
    } = params;
    const { request } = useHttp();
  
    const onSearchDevices = async (value) => {
      if (value.length) {
        setValue(value);
        try {

          const response = await request(`/api/registered/req_findDevice?text=${value}`);

          if (response.error) {
            setIsConfirmVisible(true);
            setSearchValue(value);
          } else {
            setIsConfirmVisible(false);
            setTotalData(response.count);
            setData(response.rows.map((el, i) => ({ ...el, key: i + 1 })));
            setSearcher(true);
          }
        } catch (error) {
          console.error('Error fetching users:', error);
          setIsConfirmVisible(true);
          setSearchValue(value);
        }
      } else {
        setSearcher(false);
        fetchList(actualSegment);
      }
    };
  
    const onSearchLocalMachine = async (value) => {
        if (value.length) {
          setValue(value);
          try {

            const response = await request(`/api/localmachine/find_localmachine?text=${value}`);
           
            if (response.error) {
              setIsConfirmVisible(true);
              setSearchValue(value);
            } else {
              setIsConfirmVisible(false);
              setTotalData(response.count);
              setData(response.rows.map((el, i) => ({ ...el, key: i + 1 })));
              setSearcher(true);
            }
          } catch (error) {
            console.error('Error fetching users:', error);
            setIsConfirmVisible(true);
            setSearchValue(value);
          }
        } else {
          setSearcher(false);
          fetchList(actualSegment);
        }
      };

      const onSearch = async (value) => {
        if (actualSegment === 'devices') {
          onSearchDevices(value);
        } else if (actualSegment === 'localmachines') {
          onSearchLocalMachine(value);
        }
      };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && inputValue.trim() !== '') {
        onSearch(inputValue);
    }};
    
    return (
      <>
        <Input
          placeholder="Поиск"
          allowClear
          style={{ marginBlock: 10, width: '45%', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          type="primary"
          style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', margin: '10px 2px 10px 0' }}
          onClick={() => onSearch(inputValue)}
          disabled={!inputValue.trim()}
        >
          Найти
        </Button>
      </>
    );
  };

export default SearchRequests;
