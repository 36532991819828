export const LOGIN_ROUTE = '/login';
export const MAIN_ROUTE = '/';
export const DIRECTORY_ROUTE = '/directory/:directoryName';
export const OFFLINE_DIRECTORY_ROUTE = '/device_offline';
export const CHART_ROUTE = '/app';
export const MAPS_ROUTE = '/map';
export const STATISTICS = '/statistics';
export const GEO = '/geo';
export const CALL_PAGE = '/call_page';
export const ROOM_PAGE = '/room/:id/:fullRoomName/:wantsEnableAudio/:wantsEnableVideo/:selectedBitrate';
export const GROUP_CALL_PAGE = '/webrtc_translations';
export const MESSAGES_ROUTE = '/messages';
export const REG_REQUESTS_ROUTE = '/registered';
export const TRANSLATION = '/translation';
export const USERS = '/users';
export const USERS_REQUESTS = '/users_requests';
export const GROUP_TRANSLATIONS = '/group_translations';
export const LICENSE_EXTENSION = '/license_extension';
export const UDP_TRANSLATION = '/udp_translation';
export const LOCALMACHINE_REQUESTS_ROUTE = '/localmachine_registration';
export const PASSWORD_PATTERN = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{6,}$/;
export const MAP_FILENAME_PATTERN = /^[a-zA-Z0-9_.-]+$/;

export const MAP_TYPES = [
  { value: 'sat', label: 'Sat' },
  { value: 'map', label: 'Map' },
  { value: 'dem', label: 'Dem' },
  { value: 'phlr', label: 'Phlr' },
  { value: 'vect', label: 'Vect' },
  { value: 'hgt', label: 'Hgt' },
];

export const GEO_OPTION_VALUES = {
  STRONG_POINT: 'strongPoint',
  LBS: 'LBS',
  MAPS: 'maps',
};

export const GEO_OPTIONS = [
  {
    label: 'Опорные пункты',
    value: GEO_OPTION_VALUES.STRONG_POINT,
  },
  {
    label: 'ЛБС',
    value: GEO_OPTION_VALUES.LBS,
  },
  {
    label: 'Карты',
    value: GEO_OPTION_VALUES.MAPS,
  },
];

// Стандартные размеры экрана bootstrap
export const SCREEN_SM = 576;
export const SCREEN_MD = 768;
export const SCREEN_LG = 992;
export const SCREEN_XL = 1200;
export const SCREEN_XXL = 1400;

// Размеры для трансляций
export const HEIGHT_VALUES = {
  1: '90vh',
  2: '90vh',
  3: '43vh',
  4: '43vh',
  5: '43vh',
  6: '43vh',
  7: '27vh',
  8: '27vh',
  9: '27vh',
};
export const SPAN_CHANGER = {
  1: 24,
  2: 12,
  3: 12,
  4: 12,
  5: 8,
  6: 8,
  7: 8,
  8: 8,
  9: 8,
};
