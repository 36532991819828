import React, { useState } from 'react';
import {
  Button,
  Form,
  message,
  Input,
  Modal,
  Select
} from 'antd';
import { useHttp } from '../../hooks/http.hook';

const EditGroupDevicesModal = (params) => {
  const {fetchListDevices, actualSegment, DebounceSelect, fetchUserList, fetchDeviceList} = params;
  const { request } = useHttp();
  const [isEditGroupOpen, setEditGroupOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [deviceValue, setDeviceValue] = useState([]);
  const [form] = Form.useForm();
  const showModal = () => {
    setEditGroupOpen(true);
  };

  const handleCancel = () => {
    setEditGroupOpen(false);
  };
  const onFinish = async (values) => {
    try {
      const device_ids = values.device_ids.map(device => device.value);
      const updateData = { device_ids: device_ids };
      if (values.user_id?.value) updateData.user_id = values.user_id.value;
      if (values.call_sign) updateData.call_sign = values.call_sign;
      if (values.army_post) updateData.army_post = values.army_post;
      if (values.tg) updateData.tg = values.tg;
  
      const resData = await request(`/api/device/editMultiple`, 'PATCH', updateData);
  
      if (!resData.error) {
        message.success('Информация об устройствах успешно обновлена!');
      } else {
        message.error('Не удалось обновить информацию об устройствах!');
      }

      setEditGroupOpen(false);
    } catch (error) {
      console.log('ERROR  onGenerateNewIdModal  forwarder>>>', error);
      if (error.message === 'Validation error') {
        message.info('ОШИБКА создания! Ошибка валидации запроса на обновление информации об устройствах');      
      }
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal} style={{ float: 'right', marginBlock: 10, marginRight: 2 }}>   
        Редактировать
      </Button>
      <Modal title="Редактировать устройства"
        open={isEditGroupOpen}
        onOk={() => {
          form.validateFields()
            .then((values) => {
              form.resetFields();
              onFinish(values);
            })
            .catch((info) => {
              console.log('Validate failed: ', info);
            })
        }}
        onCancel={handleCancel}
        cancelText="Отмена"
        >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          autoComplete="off"
        >

          <Form.Item
            label="Устройства"
            name="device_ids"
            rules={[
                {
                  required: true,
                  message: 'Необходимо выбрать хотя бы одно устройство!',
                },
              ]}
          >
            <DebounceSelect
              mode='multiple'
              value={deviceValue}
              placeholder="Выберите устройства"
              fetchOptions={fetchDeviceList}
              onChange={(newValue) => {
                setDeviceValue(newValue)
              }}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <hr style={{ marginBottom: '20px', borderTop: '0px solid #e0e0e0'}}/>

          <Form.Item
            label="Позывной"
            name="call_sign"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Военная часть"
            name="army_post"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пользователь"
            name="user_id"
          >
            <DebounceSelect
              value={value}
              placeholder="Выберите пользователя"
              fetchOptions={fetchUserList}
              onChange={(newValue) => {
                setValue(newValue)
              }}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item
            label="Telegram"
            name="tg"
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditGroupDevicesModal;