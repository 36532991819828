import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import './index.css';

import styles from './index.module.css';

import { isMobile } from 'react-device-detect';
import Drawer from '../DrawerProfile/DrawerProfile';

import {
  LogoutOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  MenuOutlined,
  BookOutlined,
  AreaChartOutlined,
  MessageOutlined,
  BorderOuterOutlined,
  DesktopOutlined,
  VideoCameraAddOutlined,
  AuditOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { AuthContext } from '../../context/AuthContext';
import DrawerProfile from '../DrawerProfile/DrawerProfile';

const NavBar = () => {
  const auth = useContext(AuthContext);
  const { isAdmin, userState, status } = useSelector((state) => state.userInfo);
  const showUnreadIndicator = useSelector((state) => state.ui.showUnreadIndicator);
  const translationGroup = useSelector((state) => state.userInfo.translationGroup);
  const [isDot, setIsDot] = useState(false);

  useEffect(() => {
    if (typeof translationGroup === 'string') {
      setIsDot(true);
    } else {
      setIsDot(false);
    }
  }, [translationGroup]);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    window.location.reload();
  };

  const items = [
    {
      key: 'forwarder',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/directory/forwarder' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <SnippetsOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/directory/forwarder" className={styles.NavHover}>
              Список устройств
            </NavLink>
         </span>
        </div>
        
      ),
    },
    {
      key: 'messages',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/messages' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <MessageOutlined className={styles.NavHover}/>
            <Badge dot={showUnreadIndicator}>
              <NavLink to="/messages" className={styles.NavHover}>
                Сообщения
              </NavLink>
            </Badge>
         </span>
        </div>
      ),
    },
    {
      key: 'app',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/app' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <AppstoreOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/app" className={styles.NavHover}>Приложения</NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'geo_data',
      label:(
          <div className={`${styles.NavHover} ${window.location.pathname === '/geo' ? styles.activeNavItem : ''}`}>
           <span className={styles.NavContainer}>
            <BorderOuterOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/geo" className={styles.NavHover}>Геоданные</NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'registration_requests',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/registered' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <ClockCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/registered" className={styles.NavHover}>Запросы</NavLink>
         </span>
        </div>
       
      ),
    },
    {
      key: 'translation',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/translation' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <PlayCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/translation" className={styles.NavHover}>
              Трансляция
            </NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'users',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/users' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <TeamOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/users" className={styles.NavHover}>Пользователи</NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'new_user_requests',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/users_requests' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <SolutionOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/users_requests" className={styles.NavHover}>
              Добавление пользователей
            </NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'group_translations',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/group_translations' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <UsergroupAddOutlined className={styles.NavHover}/>
            <Badge dot={typeof translationGroup === 'string'}>
              <NavLink to="/group_translations" className={styles.NavHover}>
                Групповые трансляции
              </NavLink>
            </Badge>
         </span>
        </div>
      ),
    },
    {
      key: 'device_offline',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/device_offline' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <BookOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/device_offline" className={styles.NavHover}>
              Оффлайн устройства
            </NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'statistics',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/statistics' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <AreaChartOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/statistics" className={styles.NavHover}>
              Статистика
            </NavLink>
         </span>
        </div>
      ),
    },
    {
      key: 'call_page',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/call_page' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <PhoneOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/call_page" className={styles.NavHover}>Звонки</NavLink>
         </span>
        </div>
      ),
    },
    // {
    //   key: 'webrtc_translations',
    //   icon: <BookOutlined />,
    //   label: <NavLink to="/webrtc_translations">Групповые звонки</NavLink>,
    // },
    {
      key: 'license_extension',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/license_extension' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <AuditOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/license_extension" className={styles.NavHover}>
              Лицензии
            </NavLink>
         </span>
        </div>
      ),
    },
    // {
    //   key: 'webrtc_translations',
    //   icon: <BookOutlined />,
    //   label: <NavLink to="/webrtc_translations">Групповые звонки</NavLink>,
    // },
    {
      key: 'localmachine_registration',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/localmachine_registration' ? styles.activeNavItem : ''}`}>
         <span className={styles.NavContainer}>
          <DesktopOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/localmachine_registration" className={styles.NavHover}>
              Локальные сервера
            </NavLink>
         </span>
        </div>
      ),
    },
  ].filter((el) => {
    if (isAdmin) {
      return true;
    } else {
      if (el.key === '1_exit') {
        return true;
      } else {
        if (userState.responseAccesses) {
          return userState.responseAccesses[el.key];
        }
      }
    }
  });

  if (isMobile) {
    items.push({
      key: '1_exit',
      icon: <LogoutOutlined />,
      label: (
        <a href="/" onClick={logoutHandler}>
          {' '}
          Выйти{' '}
        </a>
      ),
    });
  }

  return isMobile ? (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
    <div style={{ position: 'absolute', right: '20px' }}>
      <a onClick={(e) => e.preventDefault()}>
        <MenuOutlined />
      </a>
    </div>
  </Dropdown>
) : (
  <>
    <div className={styles.menuWrapper}>
      <Menu
      // onClick={}
        mode="horizontal"
        theme="dark"
        items={status === 'resolved' ? items : []}
        inlineIndent={5}
        className={styles.menuNav}
      />
    </div>

    {status === 'resolved' && <Drawer />}
  </>
);
};

export default NavBar;