import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Form,
  Input,
  notification,
  Space,
  Select,
  FloatButton,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import getFiltersInColumn from '../../utils/filteredColumns';
import { useHttp } from '../../hooks/http.hook';
import { DeleteOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { MAP_TYPES } from '../../utils/consts';
import './index.css';
import { useSelector } from 'react-redux';

const ChartMapTable = () => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [dataMbtiles, setDataMbtiles] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [nameBtn, setNameBtn] = useState(null);
  const [clickRowId, setClickRowId] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(10);
  const [totalDataMbtiles, setTotalDataMbtiles] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const uploads = useSelector(state => state.loader.uploads);
  const [processedUploads, setProcessedUploads] = useState([]);

  useEffect(() => {

    const newCompletedUploads = uploads
      .filter((upload) => upload.status === 'completed')
      .filter((upload) => !processedUploads.includes(upload.id));

    if (newCompletedUploads.length > 0) {
      fetchListChartPage();
      setProcessedUploads((prev) => [...prev, ...newCompletedUploads.map((upload) => upload.id)]);
    }
  }, [uploads, processedUploads, page]);

  // const plainOptions = [
  //   {
  //     label: '.hgt',
  //     value: '.hgt',
  //   },
  //   {
  //     label: '.mbtiles',
  //     value: '.mbtiles',
  //   },
  // ];

  const columns = isMobile
    ? [
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
          // width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              data,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'id'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return record.id;
            } else {
              return record.id?.includes(value);
            }
          },
          sorter: (a, b) => a.name?.localeCompare(b.name),
          render: (text, record) => {
            return (
              <>
                {clickRowId === record.id && 'device_id' === nameBtn ? (
                  <>
                    <Form.Item name={'name'}>
                      <Input
                        className='tableInput'
                        ref={input => input && input.focus()}
                        onBlur={() => {
                          setClickRowId();
                          setNameBtn();
                        }}
                        value={text}
                        style={{ textAlign: 'center' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: '25px',
                      }}
                      onClick={() => {
                        setClickRowId(record.id);
                        setNameBtn('device_id');
                      }}>
                      {text}
                    </div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Действия',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => {
            return (
              <div className='ChartPage__fields'>
                <ol style={{ paddingLeft: 0 }}>
                  <Space direction='vertical' size='middle'>
                    <li style={{ listStyleType: 'none' }}>
                      <Button
                        type='primary'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          deleteCurrentFile(record.id, record.path);
                        }}
                        style={{ width: '100%', position: 'relative' }}
                        danger>
                        Удалить поле
                      </Button>
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <Button
                        type='default'
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          downloadCurrentFile(record.name);
                        }}
                        style={{ width: '100%', position: 'relative' }}>
                        Скачать
                      </Button>
                    </li>
                  </Space>
                </ol>
              </div>
            );
          },
        },
      ]
    : [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              data,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'id'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return record.id;
            } else {
              return record.id?.includes(value);
            }
          },
          sorter: (a, b) => a.id - b.id,
          render: (text, record) => {
            return record.id;
          },
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
          width: 100,
          editeble: true,
          render: (text, record) => {
            return (
              <span style={{ textTransform: 'capitalize' }}>{record.type}</span>
            );
          },
        },
        {
          title: 'Имя файла',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              data,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'name'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.name) {
              return !record.name;
            } else {
              return record.name?.includes(value);
            }
          },
          sorter: (a, b) => a.name?.localeCompare(b.name),
          render: (text, record) => {
            return (
              <>
                {clickRowId === record.id && 'device_id' === nameBtn ? (
                  <>
                    <Form.Item name={'name'}>
                      <Input
                        className='tableInput'
                        ref={input => input && input.focus()}
                        onBlur={() => {
                          setClickRowId();
                          setNameBtn();
                        }}
                        value={text}
                        style={{ textAlign: 'center' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: '25px',
                      }}
                      onClick={() => {
                        setClickRowId(record.id);
                        setNameBtn('device_id');
                      }}>
                      {text}
                    </div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Путь к файлу',
          dataIndex: 'path',
          key: 'path',
          width: 150,
          editeble: true,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              data,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'path'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return !record.path;
            } else {
              return record.path?.includes(value);
            }
          },
          sorter: (a, b) => a.path?.localeCompare(b.path),
          render: (text, record) => {
            return record.path;
          },
        },
        {
          title: 'action',
          dataIndex: 'action',
          key: 'action',
          width: 150,
          render: (text, record) => {    
            return (
              <div className='ChartPage__fields'>
                <Space>
                  <Button
                    type='primary'
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      downloadCurrentFile(record.name);
                    }}>
                    Скачать карту
                  </Button>
                  <Button
                    danger
                    type='link'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      deleteCurrentFile(record.id, record.path);
                    }}>
                    Удалить поле
                  </Button>
                </Space>
              </div>
            );
          },
        },
      ];

  useEffect(() => {
    setPage(1);
    fetchListChartPage();
  }, [selectedType]);

  useEffect(() => {
    if (updatedData) {
      const newData = data?.map(item => {
        if (item.id === updatedData.id) {
          return { ...item, [updatedData.nameKey]: updatedData.values };
        } else {
          return item;
        }
      });
      setData(newData);
      setUpdatedData(null);
    }
  }, [updatedData]);

  const downloadCurrentFile = async filename => {
    try {
      selectedType === 'hgt'
        ? window.open(`api/app/map/download/${filename}`)
        : window.open(`api/app/map/download/${filename}?mbtiles=true`);
      // const resData = await request(`api/app/map/download/${filename}`, `GET`);
    } catch (err) {
      console.log('Error inside downloadCurrentFile ', err);
    }
  };

  const openNotification = custom => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type='primary'
        size='small'
        onClick={() => notification.close(key)}>
        Хорошо
      </Button>
    );
    notification.open({
      message: custom,
      btn,
      key,
    });
  };

  //* GET THE TABLE FIELDS -------------------------------------------
  const getAllFields = async values => {
    try {
      const resData = await request(`api/app/map_client`, `GET`);

      if (resData) {
        await fetchListChartPage();
      }
    } catch (error) {
      openNotification('Ошибка при получении списка файлов карт.');
      console.log('ERROR - onFinishModal - forwarder >>> ', error);
    }
  };

  //* DELETE DATA ----------------------------------------------------
  const deleteCurrentFile = async (id, path) => {
    try {
      const resData = await request(`api/app/map/${id}?target=.${path.split('.').slice(-1)[0]}`, `DELETE`);

      if (resData) {
        await fetchListChartPage();
      }
    } catch (error) {
      // openNotification('Ошибка при удалении!');
      console.log('ERROR - onDELETE - forwarder>>>', error);
    } finally {
      await getAllFields();
    }
  };

  const fetchListChartPage = async () => {
    try {
      setLoading(true);
      request(
          `api/app/map_client?offset=${page * 10}`
      )
        .then(async resData => {
          const resDataMbtiles = await request(
            selectedType
              ? `api/app/mbtiles_client?type=${selectedType}`
              : `api/app/mbtiles_client?offset=${page * 10}`
          );

          setTotalData(resData.count);
          setTotalDataMbtiles(resDataMbtiles.count);

          setData(resData.rows.map((el, i) => ({ ...el, key: i + 1 })));
          setDataMbtiles(
            resDataMbtiles.rows.map((el, i) => ({ ...el, key: i + 1 }))
          );
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  const handleTypeChange = value => {
    setSelectedType(value); // Сохраняем выбранный тип
  };

  const onFinish = async values => {
    console.log('values', values);
    try {
      await request(
           `api/app/map_client`,
        'GET'
      );
      await request(
        selectedType
          ? `api/app/mbtiles_client?type=${selectedType}`
          : `api/app/mbtiles_client`,
        'GET'
      );

      await fetchListChartPage();
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }

    console.log('values', values);
    try {
      await request(`api/app/map`, 'PATCH', {
        id: clickRowId,
        name: values['name'],
        path: values['path'],
      });

      await fetchListChartPage();
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }
  };

  // const onChangeRadio = ({ target: { value } }) => {
  //   dispatch(setMapType(value))
  //   setMbtiles(value);
  // };

  return (
    <>
      {/* <Radio.Group
        style={{ marginBottom: '2%' }}
        options={plainOptions}
        onChange={onChangeRadio}
        value={mbtiles}
        buttonStyle='solid'
      /> */}

      <Select
        placeholder='Выберите тип данных'
        options={MAP_TYPES}
        onChange={handleTypeChange}
        className='custom-select'
        style={{
          width: '250px',
          marginBottom: '2%',
        }}
      />
      {selectedType === 'hgt' ? (
        <Form form={form} onFinish={onFinish}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              current: page,
              pageSize: pageSize,
              total: totalData,
              onChange: async (page, pageSize) => {
                setLoading(true);
                setPage(page);
                setPageSize(pageSize);
                request( `api/app/map_client?offset=${page * 10}`)
                  .then(res => {
                    setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })));
                  })
                  .finally(() => setLoading(false));
              },
            }}
            bordered
          />
        </Form>
      ) : (
        <Form form={form} onFinish={onFinish}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={dataMbtiles}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              current: page,
              pageSize: pageSize,
              total: totalDataMbtiles,
              onChange: async (page, pageSize) => {
                setLoading(true);
                setPage(page);
                setPageSize(pageSize);
                request(selectedType ? `api/app/mbtiles_client?type=${selectedType}&offset=${page * 10}` :`api/app/mbtiles_client?offset=${page * 10}`)
                  .then(res => {
                    setDataMbtiles(res.rows.map((el, i) => ({ ...el, key: i + 1 })));
                  })
                  .finally(() => setLoading(false));
              },
            }}
            scroll={isMobile ? null : {
              x: 2500
            }}
            bordered
          />
        </Form>
      )}
      {selectedType ? (
        <FloatButton
          icon={<SyncOutlined />}
          tooltip={`Очистить фильтр`}
          onClick={() => {
            setSelectedType(null);
            setPage(1);
          }}
        />
      ) : null}
    </>
  );
};

export default ChartMapTable;
