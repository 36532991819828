import { configureStore } from '@reduxjs/toolkit';
import getInfoReducer from './userSlice';
import counterSlice from './counterSlice';
import createArchiveStateSlice from "./createArchiveStateSlice";
import uiReducer from './uiSlice';
import chatReducer from './chatSlice';
import unreadMessagesReducer from './unreadMessagesSlice';
import password2Reducer from './password2Slice';
import mapReducer from './mapSlice';
import loaderReducer from './loaderSlice';
import { refreshTokensMiddleware } from './refreshTokensMiddleware';

export default configureStore({
  reducer: {
    userInfo: getInfoReducer,
    counter: counterSlice,
    ui: uiReducer,
    chat: chatReducer,
    unreadMessages: unreadMessagesReducer,
    password2: password2Reducer,
    map: mapReducer,
    loader: loaderReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'uploads/addUpload',
          'uploads/reupload',
          'uploads/uploadFileInChunks',
          'uploads/uploadFileInChunks/rejected',
          'uploads/uploadChunk',
          'uploads/uploadChunk/rejected',
        ],
        ignoredPaths: ['uploads.uploads', 'payload.path', 'payload'],
      },
    })
    // .concat(refreshTokensMiddleware),
});
