import { GEO_OPTION_VALUES } from "./consts";

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getTitleText = actual => {
  let title;
  switch (actual) {
    case GEO_OPTION_VALUES.STRONG_POINT:
      title = 'Добавление опорных пунктов';
      break;
    case GEO_OPTION_VALUES.LBS:
      title = 'Добавление ЛБС';
      break;
    case GEO_OPTION_VALUES.MAPS:
      title = 'Добавление карт';
      break;
    default:
      title = 'Добавление файлов';
  }

  return title;
};

export const getUserData = () => {
  let token = null;
  let userId = null;
  const userData = localStorage.getItem('userData');

  if (userData) {
    token = JSON.parse(userData).token;
    userId = JSON.parse(userData).userId;
  }

  return { token, userId }
};