import React from "react";
import { Button, Dropdown, Menu, List, Progress, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { removeUpload, clearChunks, removeFromArray } from "../../store/loaderSlice";
import './index.css';

const UploadsDropdown = () => {

  const uploads = useSelector((state) => state.loader.uploads);
  const dispatch = useDispatch();

  const handleCancelUpload = async (upload) => {
    try {
      
      const fileName = `${upload.values.filename}_${upload.values.type}${upload.mapType}`;
      const target = upload.mapType;
      const token = upload.token;

      dispatch(removeUpload({ id: upload.id }));
      await dispatch(clearChunks({ fileName, target, token })).unwrap();
      dispatch(removeFromArray({ id: upload.id }));
      message.info('Загрузка удалена');
    } catch (err) {
      if (err.response) {
        const { status, data } = err.response;
        
        if (status === 404) {
          dispatch(removeUpload({ id: upload.id }));
          dispatch(removeFromArray({ id: upload.id }));
          message.info('Загрузка удалена');
        } else if (data) {
          if (data.message) {
            message.error(data.message || 'Ошибка загрузки файла');
          } else {
            message.error(data || 'Ошибка загрузки файла');
          }
        } else {
          message.error('Неизвестная ошибка');
        }
      }
    }
  };

  const handleClearAll = () => {
    if (uploads.length === 0) {
      message.info("Нет активных загрузок для очистки.");
      return;
    }
    
    const completedUploads = uploads.filter(upload => upload.status === 'completed');
    
    if (completedUploads.length === 0) {
      message.info("Нет завершённых загрузок для очистки.");
      return;
    }
  
    completedUploads.forEach((upload) => {
      dispatch(removeFromArray({ id: upload.id }));
    });  
  };

  const hasCompletedUploads = uploads.some((upload) => upload.status === "completed");

  const menu = (
    <>
    <div style={{ position: "relative", paddingBottom: "48px" }}>
    <Menu 
      style={{ width: 300, listStyle: 'none' }} 
      items={uploads.length === 0 ? [
        {
          label: <span>Нет активных загрузок</span>,
          disabled: true
        }
      ] : uploads.map(upload => ({
        key: upload.id,
        label: (
          <>
          <List.Item
            actions={[ 
              upload.status === "completed" ? (
                <Button
                  type="link"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(removeFromArray({ id: upload.id }));
                  }}
                >
                  Скрыть
                </Button>
              ) : upload.status === "failed" || upload.status === 'canceled' || upload.status === 'active' ? (
              <Button
              danger
                type="link"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleCancelUpload(upload);
                }}
              >
                Отменить
              </Button>
              ) : upload.status === "await" ? (
                <>
                <Button
                danger
                  type="link"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(removeFromArray({ id: upload.id }));
                  }}
                >
                  Отменить
                </Button>
                </>
              ) : null
            ]}
          >
            <List.Item.Meta
                 title={
                  (() => {
                    const nameWithoutExtension = upload.name.slice(0, upload.name.lastIndexOf('.'));
                    const extension = upload.name.slice(upload.name.lastIndexOf('.'));
                    const fileType = nameWithoutExtension.includes('_')
                      ? nameWithoutExtension.split('_').pop() // Берём часть после последнего `_`
                      : 'Неизвестный тип';
              
                    let displayName = `${nameWithoutExtension}${extension}`;
                    if (nameWithoutExtension.length > 20) {
                      displayName = `${nameWithoutExtension.slice(0, 20)}...${extension}`;
                    }
              
                    return `${displayName} (${fileType})`;
                  })()
                }
              description={
                <Progress
                  percent={upload.progress}
                  status={
                    upload.status === "failed"
                      ? "exception"
                      : upload.status === "completed"
                      ? "success"
                      : "active"
                  }
                />
              }
            />
          </List.Item>
             </>  
        ),
      }))} 
    />
    {hasCompletedUploads && (
      <Button
        type="link"
        size="small"
        style={{
          position: "absolute",
          bottom: 56,
          right: 16,
          zIndex: 100,
        }}
        onClick={(event) => {
          event.stopPropagation();
          handleClearAll();
        }}
      >
        Очистить все
      </Button>
    )}
     </div>
    </>
  );

  return (
    <>
      {uploads.length > 0 ? (
        <>
          
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button
              type="primary"
              shape="circle"
              icon={<UploadOutlined />}
              style={{ position: "fixed", top: 72, right: 20, zIndex: 1000 }}
            />
          </Dropdown>
          
        </>
      ) : null}
    </>
  );
};

export default UploadsDropdown;
